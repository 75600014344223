.search-job{
	.form-control{
		width:380px;
		
		@include respond ('tab-land'){
			width:330px;
		}
		@include respond ('phone-land'){
			width:100%;
		}
	}
}
.min-w20{
	min-width:20px;
}
.shadow_1{
	box-shadow: 0px 4px 2px 0px rgba(0,0,0,0.1);
	&:hover{
		box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
	}
}