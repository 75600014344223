body {
  background-color: "#F8F5FF" !important;
  font-size: 1rem;
}

.tab-widget {
  background: #f9f9f9;
}

.insta-post-card {
  border: 1px solid rgba(196, 196, 196, 0.46);
  border-radius: 12px;
  background: #ffffff;
  box-sizing: border-box;
  height: 100%;
}

.insta-post-card > div:nth-child(3) > img {
  width: 100%;
  height: 350px;
}

.insta-post-card > div:nth-child(3) > video {
  width: 100%;
}

.insta-post-card > div:first-child {
  padding: 15px;
}

.insta-post-card > div:nth-child(2) {
  padding: 0 15px;
}

.insta-post-card > div:first-child > img {
  width: 25px;
  border-radius: 50%;
}

.insta-post-card > div:first-child > span {
  margin-left: 15px;
  color: #5a5a5a;
  font-size: 16px;
  font-weight: 500;
}

.insta-post-card > div:nth-child(4),
.insta-post-card > div:nth-child(5) {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.insta-post-card > div:nth-child(4) h5 {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.insta-post-card > div:nth-child(4) p {
  margin: 0;
  color: #000000;
  font-size: 18px;
}

.loader-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.org-list > div > img {
  width: 50px;
  border-radius: 100%;
}

.user-greetings > h3 {
  color: #000000;
  font-size: 23px;
  font-size: 1.4vw;
  font-weight: 600;
  margin: 0;
}

.user-greetings > p {
  color: #000000;
  font-weight: 300;
  font-size: 14px;
  font-size: 0.8vw;
}

.kpi-widget {
  position: relative;
  box-shadow: -2px -2px 4px rgba(216, 200, 255, 0.32);
}

.kpi-widget.active {
  background: white;
}

.kpi-widget > div > h3 {
  color: #000000;
  font-size: 18px;
}

.kpi-widget > div > p {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.kpi-widget > i {
  position: absolute;
  font-size: 14px;
  right: 18px;
  border-radius: 75%;
  height: 25px;
  cursor: pointer;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: gainsboro;
}

.kpi-widget > i::before {
  position: relative;
  left: 1px;
}

.insta-like > i {
  color: #00000069;
  font-size: 0.8rem;
}

.insta-like > h5,
.insta-comment > h5 {
  color: #00000069 !important;
  font-size: 0.8rem !important;
}

.insta-comment > i {
  color: #00000069;
  font-size: 0.8rem;
}

.four-equals {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* column-gap: 10px;
    grid-column-gap: 10px; */
  grid-auto-columns: auto;
}

.four-equals > .mb-3,
.four-equals > .mb-5 {
  /* padding: 10px; */
}

.four-equals > .mb-3:nth-child(1n),
.four-equals > .mb-5:nth-child(1n) {
  padding-left: 0;
}

.four-equals > .mb-3:nth-child(4n),
.four-equals > .mb-5:nth-child(4n) {
  padding-right: 0;
}

.three-equals {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  column-gap: 0px;
  grid-column-gap: 0px;
  grid-auto-columns: auto;
  row-gap: 15px;
  padding-left: 10px;
}

.unsupported-media > img {
  width: 100%;
}

.unsupported-media > a > p {
  text-align: center;
  font-size: 15px;
  color: white !important;
  text-decoration: none;
}

.unsupported-media > a {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: block;
  color: #40189d;
  /*text-decoration: underline;*/
}

.border-grey.card {
  border: 1px solid #d7d7d7;
}

.table > thead > tr > th > span {
  font-weight: 500;
  font-size: 15px;
}

.table > tbody > tr > td:first-child span {
  color: #393939;
  font-size: 16px;
}

.table > tbody > tr > td {
  color: #393939;
  font-weight: 500;
}

.prop-select {
  color: #4c02ff;
  padding: 10px;
  border: none;
  background: white;
  font-size: 16px;
}

.prop-select option {
  color: black;
  height: 40px;
}

.filter-select {
  background: white;
  border: none;
  border-bottom: 1px solid #c4c5c8;
  padding: 10px;
  font-size: 16px;
}

.filter-input {
  border: none;
  border-bottom: 1px solid #c4c5c8;
  padding: 9px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.filter-input:focus {
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #4c02ff !important;
  color: #4c02ff;
}

.creator-profile-bio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.creator-profile-bio > img {
  width: 157px;
  border-radius: 50%;
}

.creator-profile-bio > div > span {
  color: #222020;
  size: 12px;
}

.creator-profile-bio > div > span:first-child > span {
  font-weight: 500;
  color: #222020;
  font-size: 24px;
}

.heading-strip {
  color: #4c02ff;
  font-size: 18px;
}

.heading-strip-mobile {
  color: #4c02ff;
  font-size: 13px;
}

.post-caption {
  height: 213px;
  overflow: auto;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  padding-bottom: 10px;
}

.rs-picker-block {
  z-index: 1 !important;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.m-font-11 {
  font-size: 11px !important;
}

.m-font-12 {
  font-size: 12px !important;
}

.insta-story {
  border: 1px solid rgba(196, 196, 196, 0.46);
  padding: 0;
  background: white;
  border-radius: 12px;
  flex: 1 1 23%;
  width: 276px;
  max-width: 23%;
}

.insta-story .story-username {
  color: #5a5a5a;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
}

.insta-story > div:first-child {
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding-bottom: 15px; */
  background: white;
}

.insta-story > div:nth-child(2) {
  padding: 0 10px;
}

.image-story-container {
  height: 350px;
  position: relative;
  padding-top: 75.25%;
}

.insta-story .image-story-container > img,
.image-story-container > img {
  position: absolute;
  width: auto !important;
  max-height: 100%;
  left: 50%;
  top: 50%;
  height: 100% !important;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.insta-story > div > .story-user {
  width: 25px;
  position: relative;
  border-radius: 50%;
  z-index: 1;
}

.carousel-video {
  height: 350px;
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
  color: unset;
}

.page_name {
  padding-left: 25px;
  margin-bottom: 15px;
}

.page_name > img {
  border-radius: 50%;
  margin-right: 10px;
}

.page_name > span {
  color: #ffffff;
  font-weight: 500;
}

.reward-type {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;
  padding: 20px 15px;
  min-height: 320px;
  width: 275px;
  cursor: pointer;
}

.reward-type-input {
  display: none;
}

.reward-type-input:checked + .reward-type {
  box-shadow: 0 0 1px 1px #2ecc71;
}

.reward-type h3 {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.reward-type p {
  font-size: 14px;
  font-weight: 400;
  color: #565656;
}

.prop-select.black {
  color: #7e7e7e;
  font-weight: bolder;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* .authincation-content {
    background: #4c02ff !important;
} */
.f-grid-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.insta-card {
  width: 276px;
  display: block;
  text-align: inherit;
  height: auto;
  background: white;
  border: 1px solid #c2c2c2;
  flex: 1 1 23%;
  border-radius: 1.25rem;
  padding-bottom: 20px;
  box-shadow: 0px 10px 20px rgba(205, 191, 208, 0.25);
  max-width: calc(25% - 10px);
}

.insta-card .user a {
  width: 35px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  height: 35px;
}

.insta-card .stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.insta-card .user {
  display: flex;
  align-items: center;
}

.insta-card .user a img {
  width: 100%;
  height: 100%;
}

.insta-card .media {
  position: relative;
}

.insta-card .media .image img,
.insta-card .media .album img {
  height: 276px;
  width: 100%;
  background: white;
  object-fit: cover;
  border-radius: 0;
}

.insta-card .user span {
  color: #414141;
  text-decoration: underline;
  font-size: 16px;
}

.post-modal .modal-content {
  border-radius: 0;
}

.post-modal .media-box {
  background: #f5f4f8;
}

.caption-box .user > div:nth-child(2) > span {
  font-size: 14px;
  color: #2f2e41;
}

.rounded-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  right: -40px;
  color: white;
  top: -7px;
  width: 30px;
  height: 30px;
  z-index: 99;
  cursor: pointer;
}

.caption-box > div:first-child {
  border-bottom: 1px solid #d8d8d8;
}

.caption-box > div:nth-child(2) {
  white-space: pre-line;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.caption-box > div:nth-child(2) a {
  font-weight: 200;
  color: #0000ffab;
  font-size: 14px;
}

.likes-comment > div:first-child > p {
  color: #909090;
  font-size: 11px;
}

.likes-comment > div:first-child > div > span {
  color: #000000;
  font-size: 14px;
}

.likes-comment > div:nth-child(2) {
  color: #4c02ff;
  font-size: 14px;
}

.tag {
  color: #32137c;
  cursor: pointer;
}
.react-tagsinput-input {
  width: auto !important;
}
/*BRAND POST*/
.brand-post-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}
.brand-post img {
  height: 276px;
  width: 276px;
  object-fit: cover;
}
.rule-form-container {
  border: 1px solid gainsboro;
}

.auto-reply-input {
  border-radius: 2px;
  background: #fff;
  border: 1px solid #dadada;
  color: #515151;
  /*height: 37px;*/
  display: flex;
  width: 100%;
  margin-left: -2px;
  padding: 4px;
}
.auto-reply-input > span > span {
  background: #32137c !important;
  color: white !important;
  border-color: #32137c !important;
  border-radius: 3px !important;
  margin: 0;
  margin-right: 5px;
}
.auto-reply-input > span > input {
  margin-bottom: 0;
}
.input-button {
  border-radius: 5px;
  height: 2vw;
  width: 5vw;
  margin-left: 48vw;
  padding: 0;
  color: white;
  background-color: #4c02ff;
}
.input-button-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #454448;
}
.basic-info {
  padding-bottom: 4px;
  border-bottom: 1px solid #dadada;
  color: #454448;
  margin-bottom: 20px;
}
/*
  GENRIC TEMPLATE FORM
 */
.genric-temp-form {
  display: grid;
  width: 100%;
  height: auto;
  gap: 20px;
  grid-template-columns: auto 340px;
}
.genric-temp-heading {
}
.genric-form-row {
  width: 100%;
}
.genric-temp-btn {
  border-radius: 5px;
  height: 43px;
  width: 141px;
  margin-left: 24vw;
  padding: 0;
  margin-top: -20px;
  color: white;
  background-color: #4c02ff;
}

/*
    TEMPLATE PREVIEW
*/
.genric-temp-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.template-preview {
  border: 6px solid #dee2e6;
  height: auto;
}
.template-preview-buttons {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
}
.template-preview-buttons > button {
  flex: 1;
  background: gainsboro;
  border: none;
  padding: 5px;
  color: black;
  font-size: 0.7vw;
  border-radius: 5px;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1050px;
  }
}

@media (max-width: 1375px) {
  .four-equals {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .insta-card,
  .insta-story {
    flex: 1 1 31.33%;
    max-width: 31.33%;
  }
}
.container {
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
}
.container .title {
  font-size: 25px;
  font-weight: 500;
  position: relative;
}
form .container .user-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.container .user-details .input-box {
  margin: 20px 0px 12px 0px;
  width: calc(100% / 2 -20px);
}
.container .user-details .input-box .details {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}
.user-details .input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
/* toggle buttons */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 12px;
  left: 5px;
  top: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 10px;
  /*left: 4px;*/
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #07AC18;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* toggle ends   */
.story-reply-box {
  border-radius: 15px;
  padding-top: 0px !important;
  background-color: white !important;
}

.inner-content {
  padding: 0 !important;
}

/* Story reply css */

.story-reply-box .story-reply-box-size,
.story-reply-box .story-reply-box-size1 {
  height: 70vh !important;
  min-height: 70vh !important;
  position: relative !important;

}

.story-reply-box .box-skew1:after {
  background: white;
}

.remove-shadow {
  box-shadow: none !important;
  border-radius: 0;
}

.remove-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.story-reply-box .story-reply-box-size .authincation-content {
  padding: 10px !important;
}

.story-reply-box .story-reply-box-size1 {
  width: 667px;
  height: 500px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .story-reply-box .story-reply-box-size1 {
    width: 90%;
    height: 400px;
  }
}

@media (max-width: 576px) {
  .story-reply-box .story-reply-box-size1 {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 400px) {
  .story-reply-box .story-reply-box-size1 {
    height: 250px;
  }
}


/* Default Styles */
.iphone-container {
  position: relative;
  width: 59%;
  height: 93%;
  margin: 50px auto;
  background: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.iphone-header {
  position: relative;
  padding: 10px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.iphone-title {
  font-size: 18px;
  font-weight: bold;
}

.iphone-chat {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 10px;
  /* background: #fff; */
  overflow-y: auto;
}


.iphone-chat-header {
  margin-top: 15px;

  /* justify-content: center; */
  /* align-items: center; */
  height: 10%;
  padding: 10px;
  /* background: #fff; */
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.iphone-chat-title {
  font-size: 16px;
  font-weight: bold;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.message-text {
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  word-break: break-all;
  color: black;
  font-size: .9rem;

}
.iphone-chat-messages{
  background: #00800057;
  border-radius: 8px;
}
.iphone-image {
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.iphone-image>label {
  background: #fff;
}

.iphone-image>img {
  filter: blur(1px);
  border-radius: 6px;
  vertical-align: middle;
  border-style: none;
  width: 40%;
  /* background: #fff; */
}


/* Responsive Styles */
@media (max-width: 768px) {
  .iphone-container {
    width: 80%;
    height: 70%;
  }
}

@media (max-width: 576px) {
  .iphone-container {
    width: 90%;
    height: 65%;
  }
}

@media (max-width: 400px) {
  .iphone-container {
    width: 100%;
    height: 60%;
    margin: 30px auto;
  }
}

.user-id {
  /* display: contents; */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-id>img {
  width: 100%;
  border-radius: 50%;
  padding: 5px;
}

.user-id>span {

  padding: 5px;
}

.enable-disable {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
}

.enable-disable-internal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blur-div {
  opacity: 0.7;
}

.blur-inout {
  pointer-events: none;
}

#iphone-x {
  height: 550px;
  width: 285px;
  display: inline-block;
  border: 12px solid #080808;
  border-radius: 44px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  position: relative;
}

#iphone-x:before {
  position: absolute;
  left: calc(50% - 28%);
  top: 0;
  width: 56%;
  background: #080808;
  height: 26px;
  content: "";
  border-radius: 0 0 15px 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  #iphone-x {
    height: 400px;
    width: 200px;
    border-width: 8px;
    border-radius: 30px;
  }

  #iphone-x:before {
    left: calc(50% - 25%);
    height: 16px;
    border-radius: 0 0 10px 10px;
  }
}

@media (max-width: 480px) {
  #iphone-x {
    height: 300px;
    width: 150px;
    border-width: 6px;
    border-radius: 20px;
  }

  #iphone-x:before {
    left: calc(50% - 22%);
    height: 10px;
    border-radius: 0 0 6px 6px;
  }
}

/* story mention reply end */